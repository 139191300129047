export const DepartmentEnum = {
  INTERNAL: "internal", // 내과
  SURGERY: "surgery", // 외과
  ENT: "ent",
  NEUROLOGY: "neurology",
} as const;

export type DepartmentEnum =
  (typeof DepartmentEnum)[keyof typeof DepartmentEnum];

// 부서별 id 매핑
export const DepartmentRecord: Record<number, DepartmentEnum> = {
  1: DepartmentEnum.SURGERY,
  2: DepartmentEnum.INTERNAL,
  3: DepartmentEnum.ENT,
  4: DepartmentEnum.NEUROLOGY,
  8: DepartmentEnum.SURGERY,
  11: DepartmentEnum.SURGERY,
};
