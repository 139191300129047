import { create } from "zustand";

export interface PatientStore {
  name: string;
  birth: string;
  gender: string;
  setName: (name: string) => void;
  setBirth: (birth: string) => void;
  setGender: (gender: string) => void;
  resetData: () => void;
}

export interface PatientDataStore {
  birth: string;
  checkUpDt: string;
  checkUpStatus: number;
  checkupId: number;
  doctorName: string;
  hospitalPatientId: number | string;
  identityGender: number | string;
  medicalDepartmentId: number;
  medicalStatus: number;
  name: string;
  patientId: number;
  phoneNumber: string;
  relatedPreClinic: number | null;
  painScore: string | number | null;
  selectedDoctorId: number;
  identityNumber: string;
  resetData: () => void;
}

export const usePatientStore = create<PatientStore>((set) => ({
  name: "",
  birth: "",
  gender: "",
  setName: (name: string) => set({ name }),
  setBirth: (birth: string) => set({ birth }),
  setGender: (gender: string) => set({ gender }),
  resetData: () => set({ name: "", birth: "" }),
}));

export const usePatientDataStore = create<PatientDataStore>((set) => ({
  birth: "",
  checkUpDt: "",
  checkUpStatus: 0,
  checkupId: 0,
  doctorName: "",
  hospitalPatientId: "",
  identityGender: 0,
  medicalDepartmentId: 0,
  medicalStatus: 0,
  name: "",
  patientId: 0,
  phoneNumber: "",
  relatedPreClinic: null,
  painScore: null,
  selectedDoctorId: 0,
  identityNumber: "",
  resetData: () =>
    set({
      birth: "",
      checkUpDt: "",
      checkUpStatus: 0,
      checkupId: 0,
      doctorName: "",
      hospitalPatientId: "",
      identityGender: 0,
      medicalDepartmentId: 0,
      medicalStatus: 0,
      name: "",
      patientId: 0,
      phoneNumber: "",
      relatedPreClinic: null,
      painScore: null,
      selectedDoctorId: 0,
      identityNumber: "",
    }),
}));

usePatientStore.subscribe((newstate, _) => {});
usePatientDataStore.subscribe((newstate, _) => {});
