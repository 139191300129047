import Logo from "@assets/icons/logo.svg";
import NewMobileImg from "@assets/imgs/new_mobile.svg";
import { t } from "i18next";
import { COLORS, typography_style_heading_md_semilbold } from "vd-component";
const KioskLeft = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 3,
        overflow: "hidden",
        background: COLORS.colorPrimaryNomal,
        padding: "0 55px",
        gap: "50px",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
          alignItems: "center",
        }}
      >
        <span
          css={[
            typography_style_heading_md_semilbold,
            { color: COLORS.colorStaticWhite, textAlign: "center" },
          ]}
          dangerouslySetInnerHTML={{
            __html: t("kioskLeft.title"),
          }}
        />
        <img src={Logo} css={{ width: "100%" }} />
      </div>
      <img src={NewMobileImg} alt="mobileImg" css={{ width: "90%" }} />
    </div>
  );
};

export default KioskLeft;
